.spinner {
    width: 18px;
    height: 18px;
    border: 3px solid #3498db;
    border-top: 3px solid #eff4f9;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 8px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }